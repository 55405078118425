import {isNumber} from 'lib/guards';
import {TestIdProp} from 'lib/testing/types';
import React, {memo} from 'react';
import {defineMessages, FormattedMessage, FormattedNumber} from 'react-intl';

function normalizeMinimumFractionDigits(value: number): number {
  const roundedToHundredths = Math.round(value * 100) / 100;
  return roundedToHundredths % 1 === 0 ? 0 : 2;
}

const messages = defineMessages({
  free: {
    defaultMessage: 'Free',
    description: 'Free',
  },
});

export type PriceTestId = unknown;

type PriceProps = TestIdProp<PriceTestId> & {
  minimumFractionDigits?: number;
  noSchema?: boolean;
  noText?: boolean;
  value: number;
} & (
    | {
        currency: string;
        itemProp?: string;
        itemType?: string;
      }
    | {
        currency?: never;
        itemProp?: never;
        itemType?: never;
      }
  );

export const Price = memo(function Price({
  currency,
  itemProp,
  itemType,
  minimumFractionDigits,
  noText = false,
  noSchema = false,
  testId,
  value,
}: PriceProps): React.ReactElement {
  if (!noText && value === 0) {
    return (
      <span data-test-id={testId}>
        <FormattedMessage {...messages.free} />
      </span>
    );
  }

  const withSchema = !noSchema;

  const normalizedMinimumFractionDigits = isNumber(minimumFractionDigits)
    ? minimumFractionDigits
    : normalizeMinimumFractionDigits(value);
  const normalizedCurrency = currency || undefined;
  const style = normalizedCurrency ? 'currency' : 'decimal';

  let metaProps;
  let metaPrice;
  let metaCurrency;

  if (withSchema) {
    if (normalizedCurrency) {
      metaProps = {
        itemProp,
        itemScope: true,
        itemType: itemType || 'https://schema.org/PriceSpecification',
      };
      metaPrice = String(value);
      metaCurrency = normalizedCurrency;
    } else {
      metaProps = {
        itemProp: 'price',
      };
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span {...metaProps} data-test-id={testId}>
      <FormattedNumber
        currency={normalizedCurrency}
        maximumFractionDigits={2}
        minimumFractionDigits={normalizedMinimumFractionDigits}
        style={style}
        value={value}
      />
      {metaPrice && <meta content={metaPrice} itemProp='price' key='metaPrice' />}
      {metaCurrency && <meta content={metaCurrency} itemProp='priceCurrency' key='metaCurrency' />}
    </span>
  );
});
